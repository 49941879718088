
// require('../datatables/dataTables.buttons')

require('../datatables/jquery.dataTables')
// require( 'datatables.net-buttons-bs4/js/buttons.bootstrap4');
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import jsZip from 'jszip'

pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake = pdfMake;
window.JSZip = jsZip;

require( 'datatables.net-buttons/js/dataTables.buttons' )();
require( 'datatables.net-buttons/js/buttons.colVis.js')();
require( 'datatables.net-buttons/js/buttons.html5.js' )();

require( 'datatables.net-buttons/js/buttons.flash.js' )();
require( 'datatables.net-buttons/js/buttons.print.js' )();

// Call the dataTables jQuery plugin
$(document).ready(function() {
  let isExcelReady = $('#dataTable').hasClass('is-excel-ready');
  let config = {};
  if (isExcelReady){
    config = {
      dom: 'lfrBtip',
      buttons: [
        // 'copy',
        // 'excelHtml5',
        // { extend: 'pdfHtml5', orientation: 'landscape', pageSize: 'letter' },
        // 'print'
        {
          text : '<i class="fas fa-file-excel"></i> <span class="d-inline-block"> Excel</span>',
          extend : 'excel',
          className : 'btn btn-success shadow'
        }, 
      ]
    };
  }
  let table = $('#dataTable').DataTable(config);

  // table.buttons().container()
  // .removeClass('btn-group')
  // .addClass('d-flex justify-content-end align-items-center')
  // .appendTo( '#dataTable_buttons_wrapper' );
//   .appendTo( '#dataTable_wrapper .row:eq(2) .col-sm-12:eq(0)' );
});
