require('./bootstrap');
require('./jquery-easing/jquery.easing')
require('./sb-admin-2')

require('./demo/datatables-demo')



require('./smoothscroll');
// require('./recover-inputs');
// require('./recover-followups');

// require('./chart.js/Chart')
// require('./demo/chart-area-demo')
// require('./demo/chart-pie-demo')
// require('./demo/chart-bar-demo')
