function toggleSidebar() {
    let sidebar = document.querySelectorAll(".sidebar");
    if(sidebar) {
        sidebar.forEach(e => {
            e.classList.toggle("active");
        });
    }
}

function sidebarToggler(){
    let btns = document.querySelectorAll(".sidebar-toggler");
    if(btns) {
        btns.forEach(element => {
            element.addEventListener('click', toggleSidebar);
        });
    }
}


window.onload = sidebarToggler();

