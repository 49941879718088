//NEWSLETTER SECTION
let newsletterSection = document.getElementById('newsletter-section');
let newsletterPopup =  document.getElementById('newsletter-popup');

let activeName = null, activeEmail = null;
// function helpers
function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if(regex.test(email)){
        activeEmail = true;
    } else {
        activeEmail = false;
    };
    // return regex.test(email);
}

let debounceName = null;
function nameValidateInput(section) {
    let newsletterName = section.querySelector('input[name="newsletter[name]"]');

    if(newsletterName !== null ){

        clearTimeout(debounceName);
        debounceName = setTimeout(function(){
            if(newsletterName.value !== '') {
                activeName = true;
                toggleHiddenNewsletterFields()
            } else {
                activeName = false;
                toggleHiddenNewsletterFields()
            }
        }, 500);
    }
}

function nameValidate(section) {
    sectionStr = '#'+section.id + ' input[name="newsletter[name]"]'
    let newsletterName = $(sectionStr);
    

    newsletterName.on('keydown', function() {
        nameValidateInput(section)
    }); 
    newsletterName.on('focusout', function() {
        nameValidateInput(section)
    }); 
    newsletterName.on('focusin', function() {
        nameValidateInput(section)
    }); 
    
}

let debounceEmail = null;
function emailValidateInput(section) {
    let newsletterEmail = section.querySelector('input[name="newsletter[email]"]');

    if(newsletterEmail !== null) {

        clearTimeout(debounceEmail);
        debounceEmail = setTimeout(function(){
            isEmail(newsletterEmail.value)
            toggleHiddenNewsletterFields()
        }, 500);
    }
}

function emailValidate(section) {
    sectionStr = '#'+section.id + ' input[name="newsletter[email]"]'
    let newsletterEmail = $(sectionStr);
    

    newsletterEmail.on('keydown', function() {
        emailValidateInput(section)
    }); 
    newsletterEmail.on('focusout', function() {
        emailValidateInput(section)
    }); 
    newsletterEmail.on('focusin', function() {
        emailValidateInput(section)
    }); 
    
}


if(newsletterSection) {
    checkForReloadedValues(newsletterSection)
    emailValidate(newsletterSection)
    nameValidate(newsletterSection)
    setAddChildBtn(newsletterSection);
}

function toggleHiddenNewsletterFields() {
    
    if( activeName && activeEmail) {
        $('.toggle-section').slideDown();
    } else {
        $('.toggle-section').slideUp();
    }
}

function checkForReloadedValues(section) {
    emailValidateInput(section);
    nameValidateInput(section);
};

function setAddChildBtn(section) {
    let btn = section.querySelector('.newsletterAddChildBtn');
    let thisSection = section;
    function addChild() {
        let originalChild = thisSection.querySelector('input[name="newsletter[children][][dob]"]').parentElement;
        let cloneSection = originalChild.cloneNode(true);

        cloneSection.querySelector('input[name="newsletter[children][][dob]"]').value = null;
        let container = document.createElement("div");
        container.classList.add('col-10')
        container.append(cloneSection);
        originalChild.parentElement.parentElement.append(container);
        setDeleteChildBtn(section) ;

    }
    btn.addEventListener('click', addChild);
    setDeleteChildBtn(section) ;
}

function setDeleteChildBtn(section) {
    let thisSection = section;
    
    let children = section.querySelectorAll('input[name="newsletter[children][][dob]"]');
    let button =  document.createElement("button");
    function clearAllDeleteButtons() {
        let buttons = thisSection.querySelectorAll('.delete-newsletter-child-container')  ;
        buttons.forEach(button => {
            button.remove();
        })
    } 
    clearAllDeleteButtons();    
    
    function deleteChild() {
        children[children.length - 1].parentElement.parentElement.remove()
        setDeleteChildBtn(thisSection);
    }

    button.classList.add('btn','btn-danger');
    button.role = 'button';
    button.type = 'button';
    button.addEventListener('click', deleteChild);

    let favicon = document.createElement("i");
    favicon.classList.add('fas','fa-trash');
    button.appendChild(favicon);

    if(children.length > 1) {
        let container = document.createElement("div");
        container.classList.add('col-2','delete-newsletter-child-container');
        container.append(button);
        children[children.length - 1].parentElement.parentElement.parentElement.appendChild(container)
    }

}


//NEWSLETTER POPUP

if(newsletterPopup && checkForNeverShowAgainNewsletterPopup()) {
    checkForReloadedValues(newsletterPopup)
    emailValidate(newsletterPopup)
    nameValidate(newsletterPopup)
    setAddChildBtn(newsletterPopup);

    setTimeout(function() {
        showNewsletterPopup()
    }, 30000)
}

function showNewsletterPopup() {
    newsletterPopup.classList.toggle('show')
    let sectionStr = '#'+ newsletterPopup.id+' .close'
    let button = $(sectionStr)
    button.click( 
        hideNewsletterPopup
    );

    let sectionDontShowStr = '#'+ newsletterPopup.id+' .never-show-again'
    let dontShowAgainButton = $(sectionDontShowStr)
    dontShowAgainButton.click( function() {
        neverShowAgainNewsletterPopup(true)
        hideNewsletterPopup()
    });

    newsletterPopup.querySelector('.overlay').addEventListener('click', hideNewsletterPopup);
}

function hideNewsletterPopup() {
    newsletterPopup.classList.remove('show')
}


function checkForNeverShowAgainNewsletterPopup() {

    let storedValueJSON = JSON.parse(window.localStorage.getItem("neverShowAgainNewsletterPopup"));
    if(storedValueJSON) {
        // if timestamp more than 1 month turn on neverShowAgainNewsletterPopup
        let oldTime = new Date(storedValueJSON.timestamp).getTime();
        let newTime = new Date().setMonth(new Date().getMonth() - 1);
        if( oldTime <= newTime ) {
            window.localStorage.removeItem("neverShowAgainNewsletterPopup");
            return true;
        }
        return !storedValue.value;
    }
    return true;

    return false;

}

function neverShowAgainNewsletterPopup(state) {
    let inputJSON = {
        value: state,
        timestamp: new Date(),
    }

    window.localStorage.setItem("neverShowAgainNewsletterPopup", JSON.stringify(inputJSON))      
}