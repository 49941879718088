function scrollToAnchor(anchor) {
    var tag = $(anchor);
    $('html,body').animate({ scrollTop: tag.offset().top + 2 }, 'slow');
}

function scrollButtons(){
    let navBtns = document.querySelectorAll(".nav-link-anchor");
    // let nav = document.querySelector("#navbarNav")
    if(navBtns)
    {
        navBtns.forEach( button => {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                // removeNavbarActive(nav);
                // button.parentElement.classList.add("active");
                scrollToAnchor(button.getAttribute("href"));
            });
        });
    }
};


scrollButtons();
